/*
 * @Author: your name
 * @Date: 2021-03-02 14:13:37
 * @LastEditTime: 2021-03-10 11:03:03
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \BlueCrossInternetHospitalGZH\src\router\appointment.js
 */
export const appointmentRoutes = [
  {
    path: '/appoint',
    name: 'appoint',
    meta: {
      title: '门诊列表',
      // customBackEvent: true,
      showBackBtn: true,
      bgClass: 'white-bg'
    },
    component: () => import('../views/appoint/appoint.vue')
  },
  {
    path: '/scheduling',
    name: 'scheduling',
    meta: {
      title: '',
      // customBackEvent: true,
      showBackBtn: true,
      bgClass: 'white-bg'
    },
    component: () => import('../views/scheduling/scheduling.vue')
  },
  {
    path: '/appointment',
    name: 'appointment',
    meta: {
      title: '预约提交',
      // customBackEvent: true,
      showBackBtn: true,
      bgClass: 'white-bg'
    },
    component: () => import('../views/appointment/index.vue')
  },
  {
    path: '/appointment/detail',
    name: 'appointmentDetail',
    meta: {
      title: '预约详情',
      // customBackEvent: true,
      showBackBtn: true,
      bgClass: 'white-bg'
    },
    component: () => import('../views/appointment/detail.vue')
  },
  {
    path: '/appointment/success',
    name: 'appointmentSuccess',
    meta: {
      title: '预约详情',
      // customBackEvent: true,
      showBackBtn: true,
      bgClass: 'white-bg'
    },
    component: () => import('../views/appointment/appointSuccess.vue')
  },
  {
    path: '/doctor/list',
    name: 'doctorList',
    meta: {
      title: '医生列表',
      // customBackEvent: true,
      showBackBtn: true,
      bgClass: 'white-bg'
    },
    component: () => import('../views/doctor/list.vue')
  },
  {
    path: '/doctor/detail',
    name: 'doctorDetail',
    meta: {
      title: '医生详情',
      // customBackEvent: true,
      showBackBtn: true,
      pageBackground: 'grey-bg'
    },
    component: () => import('../views/doctor/detail.vue')
  },
  {
    path: '/doctor/evaluate',
    name: 'evaluate',
    meta: {
      title: '患者评价',
      // customBackEvent: true,
      showBackBtn: true,
      bgClass: 'white-bg'
    },
    component: () => import('../views/doctor/evaluate.vue')
  },
  {
    path:'/registerPay',
    name: 'registerPay',
    meta:{
      title:'支付挂号费',
      pageBackground: 'grey-bg'
    },
    component: () => import('../views/appointment/registerPay.vue')
  }
]
