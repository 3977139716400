/*
 * @Author: your name
 * @Date: 2021-03-04 17:06:55
 * @LastEditTime: 2021-03-13 10:27:03
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \BlueCrossInternetHospitalGZH\src\router\patient.js
 */
export const userRoutes = [
  // {
  //   path: '/user',
  //   name: 'User',
  //   meta:{
  //     pageBackground: 'grey-bg',
  //     title:'我的',
  //     bgClass: 'theme-bg',
  //     showBackBtn: false
  //   },
  //   component: () => import('../views/user/index.vue')
  // },
  {
    path: '/myAppointment',
    name: 'myAppointment',
    meta:{
      pageBackground: 'grey-bg',
      title:'我的预约'
    },
    component: () => import('../views/user/appointment.vue')
  },
  {
    path: '/myRegister',
    name: 'myRegister',
    meta:{
      pageBackground: 'grey-bg',
      title:'我的挂号'
    },
    component: () => import('../views/user/register.vue')
  },
  {
    path: '/consultation',
    name: 'Consultation',
    meta:{
      pageBackground: 'grey-bg',
      title:'我的问诊'
    },
    component: () => import('../views/user/consultation.vue')
  },
  // {
  //   path:'/myDoctor',
  //   name:'/MyDoctor',
  //   meta:{
  //     pageBackground: 'grey-bg',
  //     title:'我的医生'
  //   },
  //   component: () => import('../views/user/myDoctor.vue')
  // },
  {
    path:'/orderList',
    name: 'OrderList',
    meta:{
      pageBackground: 'grey-bg',
      title:'我的订单'
    },
    component: () => import('../views/user/order/list.vue')
  },
  {
    path:'/checkup',
    name:'CheckUp',
    meta:{
      pageBackground:'grey-bg',
      title:'检查报告'
    },
    component: () => import('../views/user/checkup/list.vue')
  },
  {
    path:'/checkupdetail',
    name:'CheckupDetail',
    meta:{
      pageBackground:'grey-bg',
      title:'检查报告详情'
    },
    component: () => import('../views/user/checkupDetail/detail.vue')
  },
  {
    path:'/checkout',
    name:'CheckOut',
    meta:{
      pageBackground:'grey-bg',
      title:'检验报告'
    },
    component:() => import('../views/user/checkout/list.vue')
  },
  {
    path:'/checkoutdetail',
    name:'CheckoutDetail',
    meta:{
      pageBackground:'grey-bg',
      title:'检验报告详情'
    },
    component:() => import('../views/user/checkoutDetail/detail.vue')
  },
  {
    path:'/checkoutmore',
    name:'CheckoutMore',
    meta:{
      pageBackground:'grey-bg',
      title:'检验报告详情'
    },
    component:() => import('../views/user/checkoutMore/more.vue')
  },
  {
    path:'/paid',
    name: 'Paid',
    meta:{
      pageBackground: 'grey-bg',
      title:'处方详情-已支付'
    },
    component: () => import('../views/user/order/paid.vue')
  },
  {
    path: '/prescription',
    name: 'Prescription',
    meta:{
      pageBackground: 'grey-bg',
      title:'我的处方'
    },
    component: () => import('../views/user/prescription.vue')
  },
  {
    path: '/inReview',
    name: 'inReview',
    meta:{
      pageBackground: 'grey-bg',
      title:'处方订单详情'
    },
    component: () => import('../views/user/prescriptionDetail/inReview.vue')
  },
  {
    path: '/pay',
    name: 'Pay',
    meta:{
      pageBackground: 'grey-bg',
      title:'处方订单详情-待支付'
    },
    component: () => import('../views/user/prescriptionDetail/pay.vue')
  },

  {
    path: '/purchased',
    name: 'Purchased',
    meta:{
      pageBackground: 'grey-bg',
      title:'处方订单详情-已购买'
      // customBackEvent: true
    },
    component: () => import('../views/user/prescriptionDetail/purchased.vue')
  },
  {
    path: '/accountSettings',
    name: 'AccountSettings',
    meta:{
      pageBackground: 'grey-bg',
      title:'账号设置'
    },
    component: () => import('../views/user/accountSettings/index.vue')
  },
  {
    path: '/updatePwd',
    name: 'UpdataPassword',
    meta:{
      title:'修改密码'
    },
    component: () => import('../views/user/accountSettings/updatePwd.vue')
  },
  {
    path: '/forgetNumber',
    name: 'ForgetNumber',
    meta:{
      title:'忘记密码'
    },
    component: () => import('../views/user/accountSettings/forgetNumber.vue')
  },
  {
    path: '/setPassword',
    name: 'setPassword',
    meta:{
      title:'设置新密码'
    },
    component: () => import('../views/user/accountSettings/setPassword.vue')
  },
  {
    path: '/address',
    name: 'Address',
    meta:{
      title:'地址管理'
    },
    component: () => import('../views/user/addressList.vue')
  },
  {
    path: '/addressAdd',
    name: 'Address',
    meta:{
      title:'新增地址'
    },
    component: () => import('../views/user/addressAdd.vue')
  },
  {
    path: '/addressEdit',
    name: 'Address',
    meta:{
      title:'修改地址'
    },
    component: () => import('../views/user/addressAdd.vue')
  },
  {
    path: '/imageReport',
    name: 'ImageReport',
    meta: {
      title: '影像报告'
    },
    component: () => import('../views/user/imageReport/imageReport.vue')
  },
  {
    path: '/ft-report-detail',
    name: 'FtReportDetail',
    meta: {
      title: '报告详情'
    },
    component: () => import('../views/user/imageReport/ftReportDetail.vue')
  },
  // {
  //   path: '/more',
  //   name: 'More',
  //   meta:{
  //     title:'更多',
  //     pageBackground: 'grey-bg'
  //   },
  //   component: () => import('../views/user/more/index.vue')
  // },
  // {
  //   path: '/aboutUs',
  //   name: 'AboutUs',
  //   meta:{
  //     title:'关于我们'
  //   },
  //   component: () => import('../views/user/more/aboutUs.vue')
  // },
  // {
  //   path:'/policy',
  //   name: 'policy',
  //   meta:{
  //     title:'关于我们'
  //   },
  //   component: () => import('../views/user/more/policy.vue')
  // },
  // {
  //   path:'/statement',
  //   name: 'statement',
  //   meta:{
  //     title:'免责声明'
  //   },
  //   component: () => import('../views/user/more/statement.vue')
  // },
  {
    path:'/expenselist',
    name:'expenselist',
    meta:{
      title:'费用列表'
    },
    component:()=>import('../views/expenseList/expenseList.vue')
  }
]
