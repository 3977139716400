/*
 * @Author: your name
 * @Date: 2021-03-02 12:09:00
 * @LastEditTime: 2024-11-18 18:00:09
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \szlsnk-user-mp\src\router\permission.js
 */
import router from './index'
import store from '../store'
import { SET_HEADER_CONFIG } from '../store/mutation-types'

const WHITE_LIST = ['/login', '/register', '/','/gjcMain', '/user', '/hospitalRoute', '/hospitalIntroduction', '/introduce/privacy', '/introduce/service']
const WHITE_ROUTE_NAME_LIST = ['videoChat']

router.beforeEach((to, from, next) => {
  const metaInfo = { showBackBtn: true, bgClass: 'white-bg', ...to.meta }
  store.commit(`header/${SET_HEADER_CONFIG}`, metaInfo)
  if (WHITE_LIST.includes(to.path) || WHITE_ROUTE_NAME_LIST.includes(to.name)) {
    return next()
  }
  if (!store.getters.token) {
    // next('/login')
    next()
  } else {
    next()
  }
})
