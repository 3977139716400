<template>
  <div>
    <div class="card-section">
      <div class="cell_title">
        名称
      </div>
      <div class="cell_value">
        数量
      </div>
      <div class="cell_price">
        单价
      </div>
    </div>
    <div v-if="list.length >0" class="card-item">
      <div v-for="drug in list" :key="drug.id" class="cell">
        <div class="cell_title">
          {{ drug.xmbzmc }}
        </div>
        <div class="cell_value">
          {{ drug.fysl }}{{ drug.ftdw }}
        </div>
        <div class="cell_price red-color">
          ¥ {{ drug.dj }}
        </div>
      </div>
    </div>
    <div class="card-bottom">
      <div>煎药：{{ data.zydj ==='1'?'代煎':'不代煎' }}, <span style="margin-left:12px">贴数：{{ data.zyts }}</span></div>
      <div>备注信息：{{ data.zybzxxmc }}</div>
    </div>
  </div>
</template>
<script>
export default {
  name:'ZyDrugItem',
  props:{
    data:{
      type:Object,
      default:()=>{return {}}
    },
    list:{
      type:Array,
      default:()=>{return []}
    },
    showButton:{
      type:Boolean,
      default:false
    }
  }
}
</script>
<style scoped lang='less'>
@import "../../assets/styles/variable.less";
.card-section{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    padding: 16px 0;
    color: @dark-font-color;
    font-size: 28px;
    line-height: 40px;
}
.card-bottom{
    padding-top: 16px;
    color: @dark-font-color;
    font-size: 28px;
    line-height: 40px;
}
  .card-item{
    width: 100%;
    background: #FFFFFF;
    .cell {
      position: relative;
      display: flex;
      box-sizing: border-box;
      width: 100%;
      padding: 16px 0;
      font-weight: 400;
      color: @dark-font-color;
      font-size: 28px;
      line-height: 40px;
    }

    &:first-child{
      margin-top: 32px;
    }
    .row{
      display: flex;
      .drug-img{
        width:139px;
        height: 139px;
        flex:0 0 139px;
        margin-right: 34px;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .right{
        line-height: 40px;
        flex: 1;
      }
      .drugName{
        width: 100%;
        font-size: 24px;
        font-weight: bold;
        color: #666;
        margin-right: 10px;
        .count{
          float: right;
        }
      }

      .margin14{
        margin: 14px 0;
      }
      .font-size{
        font-size:28px;
      }

    }
  }
    .cell_title {
      flex: 2 ;
    }
     .cell_value, .cell_price {
      flex: 1 ;
    }
    .cell_price {
      text-align: right;
    }
  /deep/ .van-button--round{
    width:100% !important;
    margin-top:20px;
  }


</style>
