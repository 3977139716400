<template>
  <div>
    <div v-for="item in data" :key="item.id" class="card-item">
      <div class="consultation">
        <div class="left">
          <default-image url="" is-avatar round />
          <span class="name">{{ item.ysxm }}</span>
          <span class="shu">丨</span>
          <span>{{ item.zc }}</span>
          <span class="shu">丨</span>
          <span>{{ item.ksmc }}</span>
        </div>
        <div class="right">
          {{ item.hzztmc }}
        </div>
      </div>
      <div class="text-box desc">
        主诉:{{ item.bqzs==''||item.bqzs==null?'暂无':item.bqzs }}
      </div>
      <div class="text-box fontSize">
        就诊人:{{ item.jzrxm }}
      </div>
      <div class="border" />
      <div class="cell">
        <div class="cell_title time">
          {{ item.jzrq.substring(0,10) }} {{ item.sxwbzmc }}
        </div>
        <div class="cell_value">
          <div class="status blue" @click="toChat(item)">
            进入诊室
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name:'ConsultationItem',
    props:{
      data: {
        type: Array,
        default: () => ([])
      },
      username:{
        type:String,
        default:''
      }
    },
    methods:{
      toChat(item){
        localStorage.setItem('wenzhen',JSON.stringify(item))
        this.$router.push({path:'/chat',query:{patientId:item.patientId,id:item.id,qdxh:item.qdxh,hzzt:item.hzzt,hyid:item.hyid,fzlsh:item.fzlsh,username:item.username || this.username,password:item.password}})
      }
    }
  }
</script>
<style scoped  lang='less'>
@import "../../assets/styles/variable.less";
.card-item{
  width: 100%;
  background: #FFFFFF;
  border-radius: 12px;
  margin-bottom: 32px;
  padding-bottom:16px;
  .consultation{
    padding: 24px ;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .left{
      display: flex;
      flex-direction: row;
      align-items: center;
      align-items: center;
      font-size: 24px;
      color:#666666;
      .name{
        color:#3D3D3D;
        margin-left:20px;
      }
      .shu{
        padding:0 12px;
      }
    }
    .right{
      color: #F16F26;
      font-size: 28px;
    }

  }
  .border{
    border-top: 1px solid #EEEEEE;
   margin-bottom: 24px;
    width:90%;
    margin-left: 5%;
  }
  .time{
    color:#999999;
    font-size:24px;
  }
  .desc{
    font-size: 30px;
    color: #3D3D3D;
    font-weight: bold;
  }
  .text-box{
    padding: 0 32px 32px;

  }
  .fontSize{
    font-size: 26px;
  }
  .cell{
    position: relative;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    padding: 0px 32px;
    overflow: hidden;
    color: @dark-font-color;
    font-size: 28px;

  }

  .cell_title, .cell_value{
    flex: 1;
  }
  .cell_value{
    overflow: hidden;
    text-align: right;
  }

}
.red-color{
  color: #FF2C23;
}
.mt10{
  margin-top: 10px;
}
.statusText{
  color: #F16F26;
  font-size: 28px;
}
.status{
  font-size: 24px;
  width: 138px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  border-radius: 24px;
  float: right;
}
.blue{
  border: 1px solid #1C9CF6;
  color: #1C9CF6;
}

</style>
