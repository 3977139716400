/*
 * @Author: your name
 * @Date: 2021-03-05 10:01:29
 * @LastEditTime: 2023-10-10 17:57:10
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \szlsnk-user-mp\src\router\chat.js
 */
export const chatRoutes = [
  {
    path: '/chat',
    name: 'chat',
    meta: {
      title: '聊天室',
      // customBackEvent: true,
      showBackBtn: true,
      bgClass: 'white-bg'
    },
    component: () => import('../views/chat/Chat.vue')
  },
  {
    path: '/video-chat/:confrId',
    name: 'videoChat',
    meta: {
      title: '视频聊天',
      // customBackEvent: true,
      showBackBtn: true,
      bgClass: 'white-bg'
    },
    component: () => import('../views/chat/video.vue')
  },
  {
    path: '/trtc-chat/:confrId',
    name: 'trtcVideo',
    meta: {
      title: '视频聊天',
      // customBackEvent: true,
      showBackBtn: true,
      bgClass: 'white-bg'
    },
    component: () => import('../views/chat/trtcVideo.vue')
  }
  
]
