/*
 * @Author: your name
 * @Date: 2021-03-09 14:23:12
 * @LastEditTime: 2024-11-20 13:51:10
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \szlsnk-user-mp\src\store\getters.js
 */
const getters = {
  token: state => state.user.token,
  refreshToken: state => state.user.refreshToken,
  user: state => state.user.user,
  phoneNum: state => state.user.user.phoneNum,
  patient: state => state.user.patient,
  wxLogin: state => state.user.wxLogin
}
export default getters
